import TopNav from "../../components/Header/TopNav/TopNav";
import NavBar from "../../components/Header/Navbar/NavBar";

import ButtonsNews from '../../components/NewsPage/News';

import FloatingButton from '../../components/FloatingButton/FloatingButton';

import Footer from "../../components/Footer/Footer";

function News() {
  return (
    <div>
      <TopNav/>
      <NavBar/>
      <ButtonsNews/>
      <FloatingButton/>
      <Footer/>
    </div>
  )
}

export default News
