import { ArrowRightOutlined } from "@ant-design/icons";
import { Row, Col, Button } from "antd";
import seviceImage1 from "./service_HouseOwner.1.jpg";
import { useState, useEffect } from "react";
import Select from "react-select";
import "./ServiceApartStyle.css";

const ServiceApart = () => {
  const [serviceApart, setServiceApart] = useState([]);

  /*get service apartment list*/
  useEffect(() => {
    const getServiceApart = async () => {
      const serviceApartFromServer = await fetchServiceApart();
      setServiceApart(serviceApartFromServer);
    };
    getServiceApart();
  }, []);

  const fetchServiceApart = async () => {
    const url_service_apart =
      "https://api.betterhomes.site/public/api/v1/service";
    const res = await fetch(url_service_apart);
    const data = await res.json();
    return data["data"];
  };
  /*--------------*/

  const getDate = (date) => {
    const getDate = date.substring(0, 10);

    const dateArray = getDate.split("-");

    const formatedDate = dateArray[2] + "-" + dateArray[1] + "-" + dateArray[0];

    return formatedDate;
  };

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  return (
    <section>
      {/* SELL YOUR HOUSE */}
      <Row
        className="sellfHouseOwner"
        gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
      >
        <Col xs={1} sm={1} md={1} lg={1} xl={2}>
          <div className="greenLine" style={{ float: "left" }}></div>
        </Col>
        <Col xs={22} sm={22} md={11} lg={11} xl={9}>
          <div className="sellhouse_info">
            <h1>
              <span style={{ color: "#00908E" }}>Service</span> Apartment
            </h1>
            <p style={{ textAlign: "justify" }}>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed velit
              orci, bibendum id malesuada sed, elementum ac sem. Sed sem erat,
              placerat nec tellus vel, pretium auctor erat. In bibendum
              tincidunt urna ac blandit. Sed aliquam vestibulum quam et
              sollicitudin. Vivamus vehicula est nec molestie eleifend. Donec
              diam nisi, fermentum a tempus in, feugiat ut nunc.
            </p>
          </div>
        </Col>
        <Col className="imgSell" xs={24} sm={24} md={12} lg={12} xl={13}>
          <img src={seviceImage1} className="img-fluid" alt="sellImage"></img>
        </Col>
      </Row>

      <div className="title_service_apart mt-4">
        <span>LIST SERVICE APARTMENTS</span>
      </div>

      <div className="service_apart_page mt-3">
        <div className="block_service_item apart_service_area">
          <div className="row">
            {serviceApart.map((serviceApart) => (
              <div className="text-center col-lg-4 col-md-6 col-sm-6 col-12 item_service_area">
                <div className="item_service">
                  <div className="item_image">
                    <img
                      className="img_fluid"
                      src={serviceApart.image}
                      alt="flat"
                    />
                    <div className="item_price">
                      <span>{numberWithCommas(serviceApart.price)} VND</span>
                      <span>
                        {serviceApart.view_count} <i class="bi bi-eye"></i>
                      </span>
                    </div>
                    <div className="item_date">
                      <span>
                        {serviceApart.available_from
                          ? "From: " + getDate(serviceApart.available_from)
                          : ""}
                      </span>
                    </div>
                  </div>
                  <div className="item_description">
                    <div className="mb-3 item_description_top">
                      <h4>{serviceApart.apartment_code}</h4>
                      <span>{serviceApart.project_name}</span>
                      <br />
                    </div>
                    <div className="align-items-center item_detail">
                      <div className="detail_service_apart">
                        {serviceApart.num_beds}
                        <br />
                        bed
                      </div>
                      <div className="detail_service_apart">
                        {serviceApart.num_baths}
                        <br />
                        baths
                      </div>
                      <div className="detail_service_apart">
                        {serviceApart.sqft}
                        <br />
                        SQM
                      </div>
                      <div className="detail_service_apart">
                        {serviceApart.year_built}
                        <br />
                        Year
                      </div>
                    </div>
                    <div className="align-items-center">
                      <a
                        href={`/details/${serviceApart.id_apartment}`}
                        className="item_title"
                      >
                        <button className="btn_detail_service_apart">
                          VIEW
                        </button>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};
export default ServiceApart;
