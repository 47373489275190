import "./PersonContactStyle.css";

import React from "react";

const PersonContact = () => {
  return (
    <div>
      <div className="contact_body_area mt-3">
        <div className="block_contact col-lg-6 col-md-6 col-sm-6 col-12">
          <div className="block_image">
            <div className="image_area">
              <img className="img-fluid" src="./images/ava1.jpg" alt="ava" />
            </div>
            <div className="contact_name">
              <span>Tran Dong Giang</span>
            </div>
          </div>
          <div className="contact_infor_area">
            <div className="w-100">
              <div className="contact_details">
                <img
                  className="img-fluid image_icon"
                  src="./images/talk.png"
                  alt="contact-detail"
                />
                <span>(+84) 903 453 234</span>
              </div>
              <div className="contact_details">
                <img
                  className="img-fluid image_icon"
                  src="./images/whatsapp.png"
                  alt="contact-detail"
                />
                <span>(+84) 903 453 234</span>
              </div>
              <div className="contact_details">
                <img
                  className="img-fluid image_icon"
                  src="./images/zalo.png"
                  alt="contact-detail"
                />
                <span>(+84) 903 453 234</span>
              </div>
            </div>
          </div>
        </div>

        <div className="block_contact col-lg-6 col-md-6 col-sm-6 col-12">
          <div className="block_image">
            <div className="image_area">
              <img className="img-fluid" src="./images/ava2.jpg" alt="ava" />
            </div>
            <div className="contact_name">
              <span>Micheal Jackson</span>
            </div>
          </div>
          <div className="contact_infor_area">
            <div className="w-100">
              <div className="contact_details">
                <img
                  className="img-fluid image_icon"
                  src="./images/talk.png"
                  alt="contact-detail"
                />
                <span>(+84) 903 453 234</span>
              </div>
              <div className="contact_details">
                <img
                  className="img-fluid image_icon"
                  src="./images/whatsapp.png"
                  alt="contact-detail"
                />
                <span>(+84) 903 453 234</span>
              </div>
              <div className="contact_details">
                <img
                  className="img-fluid image_icon"
                  src="./images/zalo.png"
                  alt="contact-detail"
                />
                <span>(+84) 903 453 234</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PersonContact;
