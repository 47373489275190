import TopNav from "../../components/Header/TopNav/TopNav";
import NavBar from "../../components/Header/Navbar/NavBar";

import ServiceApart from '../../components/ServiceApartmentPage/ServiceApart';
import FloatingButton from '../../components/FloatingButton/FloatingButton';

import Footer from "../../components/Footer/Footer";

const ServiceForApartment = () => {
  return (
    <div className='main_serviceApart' style={ {overflowX: "hidden"} }>
      <TopNav/>
      <NavBar/>
      <ServiceApart/>
      <Footer/>
      <FloatingButton/>
    </div>
  )
}

export default ServiceForApartment
