import TopNav from "../../components/Header/TopNav/TopNav";
import NavBar from "../../components/Header/Navbar/NavBar";

import IntroAboutUs from "../../components/AboutUsPage/IntroAboutUs/IntroAboutUs";
import WhyChooseUs from "../../components/AboutUsPage/WhyChooseUs/WhyChooseUs";

import FloatingButton from "../../components/FloatingButton/FloatingButton";

import Footer from "../../components/Footer/Footer";

function AboutUs() {
  return (
    <div style={{ overflowX: "hidden" }}>
      <TopNav />
      <NavBar />

      <div className="container">
        <IntroAboutUs />
        <WhyChooseUs />
      </div>

      <FloatingButton />
      <Footer />
    </div>
  );
}

export default AboutUs;
