import TopNav from "../../components/Header/TopNav/TopNav";
import NavBar from "../../components/Header/Navbar/NavBar";

import RentAHouseArea from '../../components/RentAHousePage/RentAHouse';

import Footer from "../../components/Footer/Footer";
import FloatingButton from '../../components/FloatingButton/FloatingButton';


const RentAHouse = () => {
  return (
    <div className='body_rentaHouse' style={ {overflowX: "hidden"} }>
      <TopNav/>
      <NavBar/>
      <RentAHouseArea/>
      <FloatingButton/>
      <Footer/>
    </div>
  )
}

export default RentAHouse
