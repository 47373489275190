import './ListServiceStyle.css'

import shophouse from './shophouse.jpg';
import coworkingspace from './coworkingspace.jpg';
import officeforrent from './officeforrent.jpg';

const ListService = () => {
    return (
        <div className='list_service_area'>
            <div className="tittle_list_service mb-2">
                <span>OUR SERVICES</span>
            </div>
            <div className="service_list_items row">
                <div className="service_item col-lg-4 col-md-4 col-sm-4 col-12 mb-2">
                    <a href="/real-este">
                        <img src={shophouse} alt="img" className='img-fluid' />
                        <div className="service_item_name">
                            <div className="circle_name mb-3">
                                <span>S</span>
                            </div>
                            <div className='service_title_name'>
                                <span>SHOP HOUSE / <br /> BUSINESS LOCATION</span>
                            </div>
                        </div>
                    </a>
                </div>

                <div className="service_item col-lg-4 col-md-4 col-sm-4 col-12 mb-2">
                    <a href="/real-este">
                        <img src={officeforrent} alt="img" className='img-fluid' />
                        <div className="service_item_name">
                            <div className="circle_name mb-3">
                                <span>O</span>
                            </div>
                            <div className='service_title_name'>
                                <span>OFFICE FOR RENT</span>
                            </div>
                        </div>
                    </a>
                </div>

                <div className="service_item col-lg-4 col-md-4 col-sm-4 col-12 mb-2">
                    <a href="/real-este">
                        <img src={coworkingspace} alt="img" className='img-fluid' />
                        <div className="service_item_name">
                            <div className="circle_name mb-3">
                                <span>C</span>
                            </div>
                            <div className='service_title_name'>
                                <span>CO-WORKING SPACE</span>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    )
}

export default ListService