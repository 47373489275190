import { useState, useEffect } from 'react';

import TopNav from "../../components/Header/TopNav/TopNav";
import NavBar from "../../components/Header/Navbar/NavBar";

import FlatForRent from "../../components/HomePage/FlatForRent/FlatForRent";
import FlatForSell from "../../components/HomePage/FlatForSell/FlatForSell";
import ServiceApart from "../../components/HomePage/ServiceApart/ServiceApart";

import Carousel from "../../components/HomePage/Carousel/Carousel";
import IntroSlider from "../../components/HomePage/IntroSlider/IntroSlider";

import CoverFlatList from "../../components/HomePage/CoverFlatList/CoverFlatList";
import ServiceHouseOwner from "../../components/HomePage/ServiceHouseOwner/ServiceHouseOwner";

import ListService from "../../components/HomePage/ListService/ListService";
import NewsList from "../../components/HomePage/NewsList/NewsList";

import FloatingButton from '../../components/FloatingButton/FloatingButton';
import Footer from "../../components/Footer/Footer";

import pic1 from './pic1.jpg';
import pic2 from './pic2.jpg';
import pic3 from './pic3.jpg';

import ReactGA from 'react-ga';

const Home = () => {

    const [description, setDescription] = useState("Hermosa Beach is a beachfront city in Los Angeles County in the U.S. state of California, United States. Its population was 19,728 at the 2020 U.S. Census. The city is located in the South Bay region of the Greater Los Angeles area; it is one of the three Beach Cities.")

    const [numApartForRent, setNumApartForRent] = useState(0)
    const [numApartForSell, setNumApartForSell] = useState(0)
    const [numServiceApart, setNumServiceApart] = useState(0)

    /*get number apart*/
    useEffect(() => {
        const getNumberApart = async () => {
            const numberApart = await fetchNumberApart()
            setNumApartForRent(numberApart['number_for_rent'])
            setNumApartForSell(numberApart['number_for_sell'])
            setNumServiceApart(numberApart['number_serivice_apart'])
        }
        getNumberApart()
    }, [])

    const fetchNumberApart = async () => {
        const url_images_list = "https://api.betterhomes.site/public/api/v1/home/numberapart"
        const res = await fetch(url_images_list)
        const data = await res.json()
        return data['data']
    }
    /*--------------*/

    useEffect(() => {
        ReactGA.pageview(window.location.pathname)
    })

    return (
        <div style={{ overflowX: "hidden" }}>
            <TopNav />
            <NavBar />
            
            <Carousel />
            <IntroSlider/>
            
            <ListService/>

            <CoverFlatList
                nameSection="SERVICE APARTMENT"
                description={description}
                picture={pic3}
                path={'/service-apartment'}
                numberApart={numServiceApart} />
            <ServiceApart />

            <CoverFlatList
                nameSection="RENT A HOUSE"
                description={description}
                picture={pic1}
                path={'/rent-a-house'}
                numberApart={numApartForRent} />
            <FlatForRent />

            <CoverFlatList
                nameSection="BUY A HOUSE"
                description={description}
                picture={pic2}
                path={'/buy-a-house'}
                numberApart={numApartForSell} />
            <FlatForSell />

            <ServiceHouseOwner/>

            <NewsList />
            <Footer />
            <FloatingButton/>

        </div>

    );
};

export default Home;