import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import Home from './pages/Home/Home';
import AboutUs from './pages/AboutUs/AboutUs';
import Contact from './pages/Contact/Contact';
import Details from './pages/Details/Details';
import News from './pages/News/News';
import RealEste from './pages/RealEste/RealEste';
import RentAHouse from './pages/RentAHouse/RentAHouse';
import BuyAHouse from './pages/BuyAHouse/BuyAHouse';
import HouseOwner from './pages/ServiceHouseOwner/HouseOwner';
import ServiceforApart from './pages/ServiceApartment/ServiceForApartment';
import NewsDetails from './pages/NewsDetails/NewsDetails';
import ReactGa from 'react-ga';
import { useEffect } from 'react';

function App() {

  useEffect(() => {
    ReactGa.initialize("UA-254143789-1")
    ReactGa.pageview(window.location.pathname)
  }, [])

  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/about' element={<AboutUs />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/news' element={<News />} />
          <Route path='/rent-a-house' element={<RentAHouse />} />
          <Route path='/buy-a-house' element={<BuyAHouse />} />
          <Route path='/real-este' element={<RealEste />} />
          <Route path='/service-apartment' element={<ServiceforApart />} />
          <Route path='/service-for-house-owner' element={<HouseOwner />} />
          <Route path='/details/:id' element={<Details />} />
          <Route path='/news-details/:id' element={<NewsDetails />} />
        </Routes>
      </Router>

    </div>

  );
}

export default App;
