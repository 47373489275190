import TopNav from "../../components/Header/TopNav/TopNav";
import NavBar from "../../components/Header/Navbar/NavBar";

import DetailsSection from '../../components/DetailsPage/Details';

import Footer from "../../components/Footer/Footer";
import FloatingButton from '../../components/FloatingButton/FloatingButton';

const Details = () => {
    return (
        <div style={{ overflowX: "hidden" }}>
            <TopNav/>
            <NavBar/>
            <DetailsSection/>
            <FloatingButton/>
            <Footer/>
        </div>
    );
};

export default Details;