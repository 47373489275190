import TopNav from "../../components/Header/TopNav/TopNav";
import NavBar from "../../components/Header/Navbar/NavBar";

import ServiceHouseOwner from "../../components/ServiceHouseOwnerPage/ServiceHouseOwner";

import Footer from "../../components/Footer/Footer";
import FloatingButton from "../../components/FloatingButton/FloatingButton";

const HouseOwner = () => {
    return(
        <div style={ {overflowX: "hidden"} }>
            <TopNav/>
            <NavBar/>
            <div className="container">
                <ServiceHouseOwner/>
            </div>
            <FloatingButton/>
            <Footer/>
        </div>
    );
}

export default HouseOwner