import './ServiceHouseOwnerStyle.css';

import sale from './sale.jpg';
import rent from './rent.jpg';
import service from './service.jpg';
import interior from './interior.jpg';

const ServiceHouseOwner = () => {
    return (
        <div className='list_service_house_onwer_area'>
            <div className="tittle_list_service_house_onwer mb-2">
                <span>SERVICE FOR HOUSE OWNER</span>
            </div>
            <div className="service_house_onwer_list_items row">
                <div className="service_house_onwer_item col-lg-3 col-md-3 col-sm-6 col-6 mb-2">
                    <a href="/real-este">
                        <img src={sale} alt="img" className='img-fluid' />
                        <div className="service_house_onwer_item_name">
                            <div className="circle_house_onwer_name mb-3">
                                <span>S</span>
                            </div>
                            <div className='service_house_onwer_title_name'>
                                <span>SALE YOUR HOUSE</span>
                            </div>
                        </div>
                    </a>
                </div>

                <div className="service_house_onwer_item col-lg-3 col-md-3 col-sm-6 col-6 mb-2">
                    <a href="/real-este">
                        <img src={rent} alt="img" className='img-fluid' />
                        <div className="service_house_onwer_item_name">
                            <div className="circle_house_onwer_name mb-3">
                                <span>R</span>
                            </div>
                            <div className='service_house_onwer_title_name'>
                                <span>RENT OUT <br /> YOUR HOUSE</span>
                            </div>
                        </div>
                    </a>
                </div>

                <div className="service_house_onwer_item col-lg-3 col-md-3 col-sm-6 col-6 mb-2">
                    <a href="/real-este">
                        <img src={service} alt="img" className='img-fluid' />
                        <div className="service_house_onwer_item_name">
                            <div className="circle_house_onwer_name mb-3">
                                <span>A</span>
                            </div>
                            <div className='service_house_onwer_title_name'>
                                <span>APARTMENT <br /> MANAGEMENT <br /> SERVICE</span>
                            </div>
                        </div>
                    </a>
                </div>

                <div className="service_house_onwer_item col-lg-3 col-md-3 col-sm-6 col-6 mb-2">
                    <a href="/real-este">
                        <img src={interior} alt="img" className='img-fluid' />
                        <div className="service_house_onwer_item_name">
                            <div className="circle_house_onwer_name mb-3">
                                <span>I</span>
                            </div>
                            <div className='service_house_onwer_title_name'>
                                <span>INTERIOR DESIGN</span>
                            </div>
                        </div>
                    </a>
                </div>

            </div>
        </div>
    )
}

export default ServiceHouseOwner