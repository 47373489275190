import TopNav from "../../components/Header/TopNav/TopNav";
import NavBar from "../../components/Header/Navbar/NavBar";

import BuyAHouseArea from '../../components/BuyAHousePage/BuyAHouse';

import Footer from "../../components/Footer/Footer";
import FloatingButton from '../../components/FloatingButton/FloatingButton';

function BuyAHouse() {
  return (
    <div className='main_BuyaHouse' style={{ overflowX: "hidden" }}>
      <TopNav />
      <NavBar />
      <BuyAHouseArea />
      <Footer />
      <FloatingButton/>
    </div>
  )
}

export default BuyAHouse
