import TopNav from "../../components/Header/TopNav/TopNav";
import NavBar from "../../components/Header/Navbar/NavBar";


import FloatingButton from '../../components/FloatingButton/FloatingButton';
import News_Details from '../../components/NewsPage/NewsDetails';

import Footer from "../../components/Footer/Footer";

function NewsDetails() {
  return (
    <div>
        <TopNav />
        <NavBar />
        <News_Details/>
        <FloatingButton/>
        <Footer/>

    </div>
  )
}

export default NewsDetails
