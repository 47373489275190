import Logo from "./logo.jpg";
import "./NavBarStyle.css";
import { Link } from "react-router-dom";

const Navbar = () => {
  return (
    <div className="nav_bar_area w-100">
      <div className="nav_bar_list">
        <div className="nav_left">
          <Link to={"/service-for-house-owner"}>Service For House Owner</Link>
          <Link to={"/real-este"}>Real Estate For Business</Link>
        </div>
        <div className="nav_middle">
          <Link to={"/"}>
            <img className="img-fluid image_logo" src={Logo} alt="logo" />
          </Link>
        </div>
        <div className="nav_right">
          <Link to={"/buy-a-house"}>Buy A House</Link>
          <Link to={"/rent-a-house"}>Rent A House</Link>
          <Link to={"/service-apartment"}>Service Apartment</Link>
        </div>
      </div>
    </div>
  );
};

export default Navbar;