import TopNav from "../../components/Header/TopNav/TopNav";
import NavBar from "../../components/Header/Navbar/NavBar";

import PersonContact from "../../components/ContactPage/PersonContact";
import InforContact from "../../components/ContactPage/InforContact";

import Footer from "../../components/Footer/Footer";
import FloatingButton from "../../components/FloatingButton/FloatingButton";

function Contact() {
  return (
    <div style={{ overflowX: "hidden" }}>
      <TopNav />
      <NavBar />
      <PersonContact />
      <InforContact />
      <FloatingButton />
      <Footer />
    </div>
  );
}

export default Contact;
