import TopNav from "../../components/Header/TopNav/TopNav";
import NavBar from "../../components/Header/Navbar/NavBar";

import RealEsteBusiness from '../../components/RealEstePage/RealEsteBusiness';

import Footer from "../../components/Footer/Footer";
import FloatingButton from '../../components/FloatingButton/FloatingButton';

const RealEste = () => {
    return (
        <div style={{ overflowX: "hidden" }}>
            <TopNav />
            <NavBar />
            <RealEsteBusiness />
            <Footer />
            <FloatingButton/>
        </div>
    )
}

export default RealEste